@mixin hook-accordion-title() {
    cursor: pointer;

    overflow: hidden;

    &::before {
        content: "";
        width: ($accordion-title-line-height * 1em);
        height: ($accordion-title-line-height * 1em);
        margin-left: $accordion-icon-margin-left;
        float: right;
        @include svg-fill($internal-accordion-close-image, "#000", $accordion-icon-color);
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .uk-open > &::before { @include svg-fill($internal-accordion-open-image, "#000", $accordion-icon-color); }
}