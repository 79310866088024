//
// Custom variables
//

// Breakpoints
$breakpoint-tablet: 768px;

// Product card
$product-card-title-max-lines: 3;
$product-card-title-font-size: 16px;
$product-card-title-line-height: 22px;
$product-card-title-max-height: $product-card-title-max-lines * $product-card-title-line-height;
$product-card-title-font-size-s: 18px;
$product-card-title-line-height-s: 24px;
$product-card-title-max-height-s: $product-card-title-max-lines * $product-card-title-line-height-s;
$product-card-price-font-size: 20px;
$product-card-add-button-width: 36px;
$product-card-add-button-height: 36px;

// List view product card
$list-view-product-card-media-width: 33.3%;
$list-view-product-card-media-min-width: 170px;
$list-view-product-card-price-font-size: 24px;

// Scrollbox
$scrollbox-max-height: 300px;

// Checkbox, radio
$control-checkbox-image: "../images/form-checkbox.png";
$control-radio-image: "../images/backgrounds/form-radio.svg";
$control-inputs-size: 16px;
$control-inputs-hover: #F8F8F8;

// Ratio
$ratio-1-1: 100%;
$ratio-3-2: 66.66%;
$ratio-4-3: 75%;
$ratio-16-9: 56.25%;

//
// Custom mixins
//

@mixin center($horizontal: true, $vertical: true) {
    position: absolute;

    @if $horizontal == true and $vertical == true {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @if $horizontal == true and $vertical == false {
        left: 50%;
        transform: translate(-50%, 0);
    }

    @if $horizontal == false and $vertical == true {
        top: 50%;
        transform: translate(0, -50%);
    }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
    h3, .uk-h3 {
        font-size: $base-h3-font-size-m;
    }
}


//
// Toolbar
//

.tm-toolbar-container:not(.uk-navbar-transparent) {
    background: $global-secondary-background;
}

.tm-toolbar-container .uk-navbar-item {
    color: $navbar-nav-item-color;
}

.tm-toolbar-container .uk-navbar-nav > li > a {
    text-transform: none;
}

.tm-toolbar-container .uk-navbar-nav > li > a,
.tm-toolbar-container .uk-navbar-item,
.tm-toolbar-container .uk-navbar-toggle {
    height: 40px;
}

//
// Navbar
//

.tm-navbar-container:not(.uk-navbar-transparent) {
    background: $global-background;
}

.tm-navbar-container {
    border-bottom: $global-border-width solid $global-border;
}

.tm-navbar-container-fixed {
    z-index: $global-z-index + 5;
}

.tm-navbar-container .uk-navbar-nav > li > a,
.tm-navbar-container .uk-navbar-item,
.tm-navbar-container .uk-navbar-toggle {
    height: 60px;
}

//
// Navbar button
//

.tm-navbar-button {
    position: relative;
    padding: 0 10px;

    @media (min-width: $breakpoint-small) {
        padding: 0 15px;
    }

    .uk-badge {
        font-size: .625rem;
        position: absolute;
        top: 10px;
        right: 5px;
        min-width: 18px;
        height: 18px;
    }
}

//
// Link to all
//

.tm-link-to-all {
    & > * {
        vertical-align: middle;
    }

    & > .uk-icon {
        position: relative;
        left: 0;
        margin-left: 3px;
        transition: left 60ms;
    }

    &:hover > .uk-icon {
        left: 2px;
    }
}

//
// Aside column
//

.tm-aside-column {
    min-width: 270px;
}

//
// Change view
//

.tm-change-view {
    display: none;

    @media(min-width: $breakpoint-tablet) {
        display: flex;
    }
}

//
// Product card
//

.tm-product-card {
    display: flex;
    flex-direction: row;
    transition: box-shadow $box-shadow-duration ease-in-out;
    background-color: $global-background;
    border-left: $global-border-width solid $global-border;
    border-bottom: $global-border-width solid $global-border;
    //box-shadow: 0 0 0 $global-border-width $global-border;

    &.uk-first-column {
        border-left: none;
    }

    @media (max-width: $breakpoint-tablet - 1) {
        width: 100%;
    }

    @media (min-width: $breakpoint-tablet) {
        flex-direction: column;

        &:hover {
            z-index: 2;
            box-shadow: $global-xlarge-box-shadow;
        }
    }
}

// Media
.tm-product-card-media {
    position: relative;
    box-sizing: border-box;
    width: 40%;
    flex-shrink: 0;

    & > .tm-ratio {
        height: 100%;
    }

    & > .tm-ratio > .tm-media-box {
        padding: 15px;
    }

    @media (min-width: $breakpoint-small) {
        & > .tm-ratio > .tm-media-box {
            padding: 20px;
        }
    }

    @media (min-width: $breakpoint-tablet) {
        width: 100%;
    }
}

// Body
.tm-product-card-body {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 15px 0;
    flex-grow: 1;

    @media (min-width: $breakpoint-small) {
        padding: 20px 20px 20px 0;
    }

    @media (min-width: $breakpoint-tablet) {
        padding: 0 20px 20px 20px;
    }
}

// Title
.tm-product-card-title {
    font-size: $product-card-title-font-size;
    line-height: $product-card-title-line-height;
    display: -webkit-box;
    display: -ms-flexbox;
    overflow: hidden;
    max-height: $product-card-title-max-height;
    margin: 0 0 20px;
    text-overflow: ellipsis;
    color: inherit;
    -webkit-line-clamp: $product-card-title-max-lines;
    -webkit-box-orient: vertical;

    @media (min-width: $breakpoint-small) {
        font-size: $product-card-title-font-size-s;
        line-height: $product-card-title-line-height-s;
        max-height: $product-card-title-max-height-s;
    }
}

.tm-product-card-properties {
    display: none;
    margin: 0;

    & > li:nth-child(n+2) {
        margin-top: 5px;
    }
}

.tm-product-card-shop {
    display: flex;
    margin-top: auto;
    align-items: flex-end;
}

.tm-product-card-prices {
    flex-grow: 1;
}

.tm-product-card-price {
    font-size: $product-card-price-font-size;
    font-weight: 500;
    line-height: 1;
}

.tm-product-card-add {
    display: flex;
    margin-left: 10px;
    align-items: flex-end;
    flex-shrink: 0;
}

.tm-product-card-add-button {
    position: relative;
    bottom: -8px;
    display: inline-flex;
    width: $product-card-add-button-width;
    height: $product-card-add-button-height;
    padding: 0;
    border-radius: 500px;
    justify-content: center;
    align-items: center;
}

.tm-product-card-add-button-text {
    display: none;
}

// Labels
.tm-product-card-labels {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;

    & > .uk-label {
        font-size: 10px;
        display: table;
        margin-bottom: 3px;
        margin-left: auto;
        padding: 0 3px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Actions
.tm-product-card-actions {
    margin-right: 10px;
    margin-bottom: 2px;

    &:only-child {
        margin-right: 0;
    }
}

.tm-product-card-action {
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
}

.tm-product-card-action-text {
    display: none;
    border-bottom: 1px dotted;
}

// List view
.tm-products-list .tm-product-card {
    width: 100%;
    border-bottom: $global-border-width solid $global-border;
    box-shadow: none;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        box-shadow: none;
    }
}

@media (min-width: $breakpoint-tablet) {

    .tm-products-list .tm-product-card {
        flex-direction: row;
    }

    .tm-products-list .tm-product-card-media {
        width: 33.3%;
    }

    .tm-products-list .tm-product-card-body {
        flex-direction: row;
        padding: 20px 20px 20px 0;
    }

    .tm-products-list .tm-product-card-info {
        padding-right: 20px;
        flex-grow: 1;
        flex-basis: 0%; // for IE
    }

    .tm-products-list .tm-product-card-properties {
        display: block;
    }

    .tm-products-list .tm-product-card-shop {
        display: block;
        box-sizing: border-box;
        width: $list-view-product-card-media-width;
        min-width: $list-view-product-card-media-min-width;
        margin: 0;
        padding-left: 20px;
        border-left: $global-border-width solid $global-border;
        flex-shrink: 0;
    }

    .tm-products-list .tm-product-card-price {
        font-size: 24px;
    }

    .tm-products-list .tm-product-card-add {
        flex-direction: column;
        margin-top: 10px;
        margin-left: 0;
    }

    .tm-products-list .tm-product-card-add-button {
        bottom: 0;
        width: 100%;
        height: auto;
        padding: 0px $button-padding-horizontal;
        border-radius: $border-rounded-border-radius;
    }

    .tm-products-list .tm-product-card-add-button-icon {
        display: none;
    }

    .tm-products-list .tm-product-card-add-button-text {
        display: block;
    }

    .tm-products-list .tm-product-card-actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
        margin-right: 0;
        margin-bottom: 0;
        order: 1;
        align-self: flex-start;

        &:only-child {
            margin-top: 0;
        }
    }

    .tm-products-list .tm-product-card-action {
        margin-right: 0;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .tm-products-list .tm-product-card-action-text {
        display: inline;
        margin-left: 5px;
    }
}

//
// Actions buttons
//

.tm-action-button-active,
.tm-action-button-active > * {
    color: $text-danger-color;
}

//
// Filters
//

.tm-filters {
    display: none;
}

.tm-filters .uk-accordion > * {
    margin-top: 0;
    border-top: $global-border-width solid $global-border;

    &:first-child {
        border-top: none;
    }
}

// Offcanvas
.tm-filters.uk-offcanvas.uk-open {
    margin: 0;
    padding: 0;
}

.tm-filters.uk-offcanvas.uk-offcanvas-overlay:before {
    position: fixed;
}

@media (min-width: $breakpoint-medium) {

    .tm-filters.uk-offcanvas {
        position: static;
        display: block;
    }

    .tm-filters.uk-offcanvas .uk-offcanvas-bar {
        position: static;
        overflow: visible;
        width: auto;
        transform: none;
        background: none;
    }

    .tm-filters.uk-offcanvas .uk-offcanvas-bar > .uk-card > .uk-card-header {
        display: none;
    }

}

//
// Scrollbox
//

.tm-scrollbox {
    overflow: auto;
    max-height: $scrollbox-max-height;
    background: linear-gradient(#FFF 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #FFF 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-color: $global-background;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
}

//
// Checkbox, radio
//

.tm-checkbox,
.tm-radio {
    display: none;

    & + label {
        position: relative;
        display: inline-flex;
        cursor: pointer;
    }

    & + label::before,
    & + label::after {
        content: "";
        display: block;
        width: $control-inputs-size;
        height: $control-inputs-size;
    }

    & + label::before {
        margin-top: 3px;
        margin-right: $global-small-margin;
        border: $global-border-width solid $global-border;
        flex-shrink: 0;
    }

    &:hover + label::before {
        background-color: $control-inputs-hover;
    }

    &:checked + label::before {
        border-color: transparent;
        //background-color: $global-primary-background;
    }

    & + label::after {
        position: absolute;
        top: 3px;
        left: 0;
        border: 1px solid transparent;
    }

    &:checked + label::after {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
}

.tm-checkbox + label::before {
    border-radius: $border-rounded-border-radius;
}

.tm-radio + label::before {
    border-radius: 50%;
}

.tm-checkbox:checked + label::after {
    @include svg-fill($control-checkbox-image, "#000", $global-inverse-color);
    background-size: cover;
}

.tm-radio:checked + label::after {
    @include svg-fill($control-radio-image, "#000", $global-inverse-color);
}

//
// Help icon
//

.tm-help-icon {
    position: relative;
    margin-left: $global-xsmall-margin;
    cursor: pointer;
    color: $global-muted-color;
    border-radius: 100%;
    background-color: $global-muted-background;

    &:hover {
        color: $global-color;
    }
}

//
// Slider
//

.tm-slider-items {

    .tm-media-box-frame {
        padding: $global-xsmall-gutter;
        transition: 0.25s linear;
        transition-property: opacity;
        opacity: 0.75;
    }

    & > :hover .tm-media-box-frame {
        opacity: 1;
    }

    & > .uk-active .tm-media-box-frame {
        opacity: 1;
        border-color: $global-primary-background;
    }
}

//
// Variations
//

.tm-variations {
    margin: 0 0 0 -10px;

    & > * {
        padding-left: 10px;
    }

    & > * > :first-child {
        color: $global-color;
        border: $global-border-width solid $global-border;
    }

    & > .uk-active > a {
        color: $global-color;
        border-color: $global-primary-background;
        background-color: transparent;
    }

    & > * > .tm-variation-color {
        padding: 5px;
        border-radius: 50%;
    }
}

.tm-variation-color > div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

//
// Product
//

.tm-product-info {
    border-top: $global-border-width solid $global-border;

    @media (min-width: $breakpoint-medium) {
        min-width: 390px;
        border-top: none;
        border-left: $global-border-width solid $global-border;
    }
}

// Price
.tm-product-price {
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
}

// Add to card button
.tm-product-add-button {
    padding: 0 15px;

    @media(min-width: 420px) {
        padding: 0 25px;
    }
}

// Description
.tm-product-description {
    border-top: $global-border solid $global-border-width;
}

// Nav
.tm-product-nav {
    display: flex;
    overflow: auto;
    background-color: $global-background;

    & > .uk-subnav {
        display: flex;
        margin: 0 auto;
        padding: 10px 15px;
        flex-wrap: nowrap;
        flex-shrink: 0;

        @media(min-width: $breakpoint-small) {
            padding: 20px;
        }

        & > :first-child {
            padding-left: 0;
        }
    }
}

.tm-product-nav-fixed {
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: inset 0 -$global-border-width 0 0 $global-border;
}

//
// Quantity
//

.tm-quantity-input {
    width: 60px;
    margin: 0 $global-xsmall-margin;
    text-align: center;
}

//
// Slidenav
//

.tm-slidenav.uk-invisible {
    visibility: visible !important;
    pointer-events: none;
    opacity: 0.3;
}

//
// Checkout
//

.tm-checkout {
    counter-reset: list;
}

.tm-checkout-title::before {
    content: counter(list) ". ";
    counter-increment: list;
    color: $global-muted-color;
}

//
// Choose
//

.tm-choose {
    position: relative;
    width: 100%;
    padding: 40px 20px 10px;
    cursor: pointer;
    border: $global-border-width solid $global-border;
    border-radius: $border-rounded-border-radius;

    &.uk-active {
        border-color: $global-primary-background;
    }

    &:not(.uk-active):hover,
    &:not(.uk-active):focus {
        background-color: $global-muted-background;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 50%;
        display: block;
        width: $control-inputs-size;
        height: $control-inputs-size;
        cursor: pointer;
        transform: translate(-50%, 0);
    }

    &::before {
        border: $global-border-width solid $global-border;
        border-radius: 50%;
        flex-shrink: 0;
    }

    &:hover::before {
        background-color: $control-inputs-hover;
    }

    &.uk-active::before {
        border-color: transparent;
        background-color: $global-primary-background;
    }

    &::after {
        position: absolute;
        border: 1px solid transparent;
    }

    &.uk-active::after {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        @include svg-fill($control-radio-image, "#000", $global-inverse-color);
    }

    .tm-choose-title {
        font-size: $global-small-font-size;
        text-align: center;
        text-transform: uppercase;
        color: $global-muted-color;
    }

    &.uk-active .tm-choose-title {
        color: $global-color;
    }

    .tm-choose-description {
        font-size: $global-xsmall-font-size;
        margin-top: $global-xsmall-margin;
        text-align: center;
        color: $global-muted-color;
    }
}

//
// Wrapper
//

// Small card
.uk-card-body.uk-card-small .tm-wrapper,
.uk-card-small .uk-card-body .tm-wrapper {
    margin-right: -$card-small-body-padding-horizontal;
    margin-left: -$card-small-body-padding-horizontal;
}

.uk-card-body.uk-card-small .tm-wrapper:first-child,
.uk-card-small .uk-card-body .tm-wrapper:first-child {
    margin-top: -$card-small-body-padding-vertical;
}

.uk-card-body.uk-card-small .tm-wrapper:last-child,
.uk-card-small .uk-card-body .tm-wrapper:last-child {
    margin-bottom: -$card-small-body-padding-vertical;
}

.uk-card-body.uk-card-small .tm-wrapper figcaption,
.uk-card-small .uk-card-body .tm-wrapper figcaption {
    margin-right: $card-small-body-padding-horizontal;
    margin-left: $card-small-body-padding-horizontal;
}

// Normal card
.uk-card-body .tm-wrapper {
    margin-right: -$card-body-padding-horizontal;
    margin-left: -$card-body-padding-horizontal;
}

.uk-card-body .tm-wrapper:first-child {
    margin-top: -$card-body-padding-vertical;
}

.uk-card-body .tm-wrapper:last-child {
    margin-bottom: -$card-body-padding-vertical;
}

.uk-card-body .tm-wrapper figcaption {
    margin-top: $global-xsmall-margin;
    margin-right: $card-body-padding-horizontal;
    margin-left: $card-body-padding-horizontal;
    text-align: center;
    color: $global-muted-color;
}

@media (min-width: $breakpoint-large) {

    .uk-card-body .tm-wrapper {
        margin-right: -$card-body-padding-horizontal-l;
        margin-left: -$card-body-padding-horizontal-l;
    }

    .uk-card-body .tm-wrapper:first-child {
        margin-top: -$card-body-padding-vertical-l;
    }

    .uk-card-body .tm-wrapper:last-child {
        margin-bottom: -$card-body-padding-vertical-l;
    }

}

//
// Compare table
//

.tm-compare-table {
    height: 0;

    td,
    th {
        vertical-align: top;
    }

    td:not(:first-child) {
        //border-left: $table-striped-border-width solid $table-striped-border;
    }

    th {
        font-size: $global-font-size;
        text-transform: none;
    }

    tbody > tr:nth-of-type(even) {
        background-color: $table-striped-row-background;
    }
}

.tm-compare-table-column {
    min-width: 240px;
}

//
// Rating
//

.tm-rating {
    margin-left: -3px;
    color: $global-muted-color;

    & > * {
        padding-left: 3px;
    }
}

//
// Reviews
//

.tm-reviews-column {
    min-width: 200px;
}

//
// Cart
//

.tm-cart-quantity-column {
    min-width: 115px !important;
}

//
// Nav
//

.tm-nav > li > a {
    padding: 10px 0;
}

.tm-nav > li.uk-active > a,
.tm-nav > li > a:hover {
    box-shadow: inset 2px 0 0 $global-primary-background;
}

.tm-nav > li.uk-active > a {
    font-weight: 500;
}

//
// Ratio
//

.tm-ratio {
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 100%;
    }

    & > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.tm-ratio-1-1::before {
    padding-top: $ratio-1-1;
}

.tm-ratio-3-2::before {
    padding-top: $ratio-3-2;
}

.tm-ratio-4-3::before {
    padding-top: $ratio-4-3;
}

.tm-ratio-16-9::before {
    padding-top: $ratio-16-9;
}

//
// Media box
//

.tm-media-box {
    display: block;
    box-sizing: border-box;
    height: 100%;
}

.tm-media-box-frame {
    border: $global-border-width solid $global-border;
    border-radius: $border-rounded-border-radius;
}

.tm-media-box-zoom {
    cursor: zoom-in;
}

.tm-media-box-wrap {
    position: relative;
    height: 100%;
    margin: 0;

    & > * > * {
        @include center();
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
}


//
// Pseudo
//

.tm-pseudo {
    border-bottom: $global-border-width dotted;
}

//
// Ignore container
//

.tm-ignore-container {
    @media(max-width: $breakpoint-small) {
        margin-right: -$container-padding-horizontal;
        margin-left: -$container-padding-horizontal;
        border-radius: 0;
    }
}

//
// Remove shadow
//

.tm-shadow-remove {
    box-shadow: none;
}

//
// Shine effect
//

.tm-shine {
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -30px;
        width: 30px;
        height: 100%;
        transform: skew(-10deg, 0deg);
        opacity: 0.3;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.03) 1%,
                rgba(255, 255, 255, 0.6) 30%,
                rgba(255, 255, 255, 0.85) 50%,
                rgba(255, 255, 255, 0.85) 70%,
                rgba(255, 255, 255, 0.85) 71%,
                rgba(255, 255, 255, 0) 100%);
    }

    &:hover::after {
        left: 100%;
        transition: .6s ease-out;
    }
}

//
// Grayscale
//

.tm-grayscale {
    filter: grayscale(100%) opacity(50%);

    &:hover {
        filter: none;
    }
}
//
// Slideshow
//

    .uk-dotnav > * > * {
        box-shadow: 0 0 7px black;
    }


    .uk-dotnav > .uk-active > * {
        background-color: $inverse-dotnav-item-active-background;
        @if(mixin-exists(hook-inverse-dotnav-item-active)) {@include hook-inverse-dotnav-item-active();}
        box-shadow: 0 0 7px black;
    }

    $inverse-slidenav-color:#000;
    .uk-slidenav {
        color: $inverse-slidenav-color!important;
        filter: drop-shadow(0 0 3px rgba(100,0,0,0.5));
    }




